<template>
  <div class="modal-container">
    <div class="modal-header">
      <woot-modal-header :header-title="pageTitle" />
      <button class="close-btn" @click="onClose">&times;</button>
    </div>

    <div class="modal-body">
      <h2>{{ title }}</h2>
      <div v-if="payload.type == 'one_off'" class="table-container">
        <table class="status-table">
          <thead>
          <tr >
            <th>Status</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(record, index) in records" :key="record.id">
            <td>
              <span :class="['icon', getStatusClass(record.status)]"></span>
            </td>
            <td>{{ record.phone_number }}</td>
            <td>{{ record.status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="payload.type == 'email'" class="table-container">
        <table class="status-table">
          <thead>
          <tr >
            <th>Status</th>
            <th>Email</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(record, index) in records" :key="record.id">
            <td>
              <span :class="['icon', getStatusClass(record.status)]"></span>
            </td>
            <td>{{ record.email }}</td>
            <td>{{ record.status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: () => ({
        title: '',
        records: [],
      }),
    },
  },
  data() {
    return {
      records: this.payload.records,
      title: this.payload.title,
    };
  },
  computed: {
    pageTitle() {
      return `Campaign Records - ${this.title}`;
    },
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    getStatusClass(status) {
      switch (status) {
        case 'sent':
          return 'sent';
        case 'failed':
          return 'failed';
        case 'pending':
          return 'pending';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #999;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #333;
}

.modal-body {
  margin: 20px 0;
  text-align: left;

  h2 {
    font-size: 1.8rem; /* Slightly larger for better visibility */
    margin-bottom: 15px;
    color: #333;
  }

  .table-container {
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
  }

  .status-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.2rem; /* Larger text for better readability */
    color: #333;

    th {
      text-align: left;
      padding: 12px; /* Increased padding */
      background-color: #007bff;
      color: #fff;
      font-weight: bold;
    }

    td {
      padding: 12px; /* Increased padding */
      text-align: left;
      vertical-align: middle;
    }

    tr:nth-child(even) {
      background-color: #f4f4f4;
    }
  }
}

.icon {
  width: 24px; /* Size of the circle */
  height: 24px; /* Size of the circle */
  border-radius: 50%; /* Make it a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust icon font size */
  color: white; /* Color of the icon (assuming it's text-based) */
}

.icon.sent {
  background-color: green; /* Sent status */
}

.icon.failed {
  background-color: red; /* Failed status */
}

.icon.pending {
  background-color: yellow; /* Pending status */
}

.modal-footer {
  margin-top: 20px;
}

.full-width-button {
  width: 100%;
  padding: 12px;
  margin: 0 20px;
  font-size: 1.1rem;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.full-width-button:hover {
  background-color: #0056b3;
}
</style>
