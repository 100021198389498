<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-header">
        <woot-modal-header header-title="Create Campaign" :header-content="campaignValidity"/>
        <button type="button" class="close" @click.prevent="onClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="modal-content flex flex-col w-full" @submit.prevent="create">
        <div  class="modal-body">
          <div class="stats-container">
            <p>{{message}}</p>
            <div v-if="payload.image_preview" class="thumbnail-preview">
              <img
                :src="payload.image_preview"
                alt="Image Preview"
                style="width: 200px; height: 150px; object-fit: cover;"
              />
            </div>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.NAME')}}:</strong> {{payload.campaign_name}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.COUNT')}}:</strong> {{payload.leads_count}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.BALANCE')}}:</strong> {{payload.account_balance_credits}}</p>
            <p><strong>{{$t('CAMPAIGN.ADD.DETAILS.COST')}}:</strong> {{payload.campaign_cost}}</p>
            <p v-if="payload.leads_count > payload.sms_limit"><strong>{{$t('CAMPAIGN.ADD.DETAILS.ALLOWED_LIMIT_LABEL')}}</strong> {{payload.sms_limit}}</p>
            <p v-if="payload.leads_count > payload.sms_limit"><strong>{{$t('CAMPAIGN.ADD.DETAILS.ALLOWED_COST_LABEL')}}</strong> {{payload.allowed_sms_cost}}</p>
          </div>
        </div>

        <div class="modal-footer">
          <woot-button variant="clear" @click.prevent="onClose">
            {{$t('CAMPAIGN.ADD.DETAILS.CANCEL')}}
          </woot-button>
          <woot-button v-if="result == 'Valid'">
            {{$t('CAMPAIGN.ADD.DETAILS.CREATE')}}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from '../../../../shared/mixins/messageFormatterMixin';
import AILoader from '../AILoader.vue';
import aiMixin from '../../../mixins/aiMixin';
import OpenAPI from '../../../api/integrations/openapi';
import { validatePhoneForE164 } from '../../../../shared/helpers/Validators';

export default {
  components: {
    AILoader,
  },
  mixins: [aiMixin, messageFormatterMixin],
  data() {
    return {};
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    campaignObj: {
      type: FormData,
      required: true
    },
    result: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
    }),
    campaignValidity(){
      console.log("result", this.result)
      return this.result == 'Valid' ? 'Campaign is ready to be created' : 'Campaign cannot be created due to low balance';
    }
  },
  methods: {
    onClose() {
      this.$emit('close-add-ids-modal');
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async create() {
      try {
        let response = await this.$store.dispatch('campaigns/create', this.campaignObj);
        if(response.status == '200' && response.data.campaign_id)
        {
          console.log("response", response);
          console.log("leads",this.payload.leads)
          let leadsObj = {
            leads: this.payload.leads,
            account_id: this.payload.account_id,
            campaign_id: response.data.campaign_id,
            total: this.payload.leads_count
          }
          let results = await this.$store.dispatch('contacts/createRecordStats', leadsObj );
          this.showAlert("Campaign Created Successfully");
          this.onClose();
        }
      } catch (error) {
        this.showAlert("Campaign creation Failed");
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-dialog {
  background: white;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 900px;
  max-width: 100%;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
}

.close {
  background: none;
  border: none;
  font-size: 4rem;
  color: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: background 0.3s, opacity 0.3s;
}

.close:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  opacity: 0.75;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 20px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: left;
  padding-left: 20px;
}

.stats-container p {
  margin-bottom: 10px;
}

.stats-container strong {
  color: #333;
  font-weight: bold;
}


.woot-button {
  margin-left: 10px;
}
</style>
