<template>
  <div>
  <div class="column content-box">
    <campaigns-table
      v-if="!openResultsTable && !openLeads"
      :campaigns="campaigns"
      :show-empty-result="showEmptyResult"
      :is-loading="uiFlags.isFetching"
      :campaign-type="type"
      @on-edit-click="openEditPopup"
      @on-delete-click="openDeletePopup"
      @on-calling-delete-click="openCallingDeletePopup"
      @on-result-click="openResultTable"
      @on-leads-click="openLeadsTable"
      @pauseCampaign="pauseCampaign"
      @playCampaign="playCampaign"
    />

    <leads-table
      v-if="openLeads"
      :campaign-id="campaignId"
      :campaign-name="campaignName"
      @close-results="openLeads = false"
    />

    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-campaign
        :selected-campaign="selectedCampaign"
        @on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />

    <woot-delete-modal
      :show.sync="showCallingDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmCallingDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />

  </div>
    <results-table
      v-if="openResultsTable"
      :campaign-id="campaignId"
      :campaign-name="campaignName"
      @close-results="openResultsTable = false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import CampaignsTable from './CampaignsTable';
import EditCampaign from './EditCampaign';
import ResultsTable from './ResultsTable.vue';
import LeadsTable from './LeadsTable.vue';
export default {
  components: {
    CampaignsTable,
    EditCampaign,
    ResultsTable,
    LeadsTable
  },
  mixins: [alertMixin, campaignMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditPopup: false,
      campaignName:'',
      openLeads:false,
      openResultsTable: false,
      campaignId:'',
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
      showCallingDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      labelList: 'labels/getLabels',
    }),
    campaigns() {
      return this.$store.getters['campaigns/getCampaigns'](this.campaignType);
    },
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaigns.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    openResultTable(response){
      this.campaignId=response.row.calling_campaign_id;
      this.campaignName=response.row.title;
      this.openResultsTable=true;
    },
    openLeadsTable(response){
      this.campaignId=response.row.calling_campaign_id;
      this.campaignName=response.row.title;
      this.openLeads=true;
    },
    pauseCampaign(response) {
      this.$store.dispatch('contacts/pause_campaign', { id: response });
    },
    playCampaign(response) {
      this.$store.dispatch('contacts/play_campaign', { id: response });
    },
    openEditPopup(response) {
      const { row: campaign } = response;
      this.selectedCampaign = campaign;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = response;
    },
    openCallingDeletePopup(response) {
      this.showCallingDeleteConfirmationPopup = true;
      this.selectedCampaign = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
      this.showCallingDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const {
        row: { id },
      } = this.selectedCampaign;
      this.deleteCampaign(id);
    },
    confirmCallingDeletion() {
      let id = this.selectedCampaign.row.id
      let campaign_id = this.selectedCampaign.row.calling_campaign_id
      this.deleteCallingCampaign(id, campaign_id);
    },
    async deleteCampaign(id) {
      try {
        await this.$store.dispatch('campaigns/delete', id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async deleteCallingCampaign(id, campaign_id) {
      try {
        const response = await axios.post(
          `https://agentapi.conversate.us/v1/campaign/cancel_campaign/${campaign_id}`,
          {},
          {
            headers: {
              Authentication: 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51',
            },
          }
        );
        await this.$store.dispatch('campaigns/delete', id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
        this.showCallingDeleteConfirmationPopup = false;
      } catch (error) {
        if(error.response.data.detail == 'Campaign not found')
        {
          await this.$store.dispatch('campaigns/delete', id);
          this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
          this.showCallingDeleteConfirmationPopup = false;
        }
        else{
          this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
          this.showCallingDeleteConfirmationPopup = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--space-one);
}

.content-box .page-top-bar::v-deep {
  padding: var(--space-large) var(--space-large) var(--space-zero);
}
</style>
