<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': showQuotedContent,
      'hide--quoted': !showQuotedContent,
    }"
  >
    <div class="from-info" v-html="sender" v-if="inboxType"></div>
    <div v-if="contentType != 'html'" class="text-content" v-html="message"></div>
    <div v-if="contentType == 'html'" class="text-content" v-html="decodedMessage"></div>
    <button
      v-if="displayQuotedButton"
      class="quoted-text--button"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent">
        <fluent-icon icon="chevron-up" class="fluent-icon" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span v-else>
        <fluent-icon icon="chevron-up" class="fluent-icon" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>

    <div v-if="channelType && checkMessage" class="reply-text">
      <span>
          {{commentReply}}
      </span>
    </div>
  </div>
</template>

<script>
import {isEmpty} from "jssip/lib/Utils";
export default {
  computed: {
    checkMessage() {
      return !isEmpty(this.commentReply)
    },
    decodedMessage() {
      return this.decodeHtml(this.message);
    }
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    contentType: {
      type: String,
      default: '',
    },
    readableTime: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    sender: {
      type: String,
      default: '',
    },
    inboxType: {
      type: Boolean,
      default: false,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
    channelType: {
      type: Boolean,
      default: false,
    },
    commentReply: {
      type: String,
      default: '',
    },
    authorName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  methods: {
    decodeHtml(encodedStr) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedStr;
      return textArea.value;
    },
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss">
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    all: revert;

    td {
      all: revert;
    }

    tr {
      all: revert;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.show--quoted {
  blockquote {
    display: block;
  }
}

.hide--quoted {
  blockquote {
    display: none;
  }
}

.quoted-text--button {
  color: var(--s-400);
  cursor: pointer;
  font-size: var(--font-size-mini);
  padding-bottom: var(--space-small);
  padding-top: var(--space-small);
  .fluent-icon {
    margin-bottom: var(--space-minus-smaller);
  }
}

.from-info {
  //color: #1f93ff;
  text-align: right;
}

.reply-text {
  border-top: 1px solid;
  padding-top: 13px;
  font-weight: 600;
}
</style>
