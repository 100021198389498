<template>
  <div v-if="show" class="modal-container">
    <div class="modal-header">
      <woot-modal-header :header-title="pageTitle" />
      <button class="close-btn" @click="onClose">&times;</button>
    </div>

    <div class="modal-body">
      <div class="stats">
        <div class="stat-item">
          <div class="icon sent"></div>
          <p><strong>Send messages:</strong> {{ stats.sent }}</p>
        </div>

        <div class="stat-item" v-if="stats.failed !== undefined">
          <div class="icon failed"></div>
          <p><strong>Failed messages:</strong> {{ stats.failed }}</p>
        </div>

        <div class="stat-item" v-if="stats.pending !== undefined">
          <div class="icon pending"></div>
          <p><strong>Pending messages:</strong> {{ stats.pending }}</p>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <woot-button variant="primary" @click="showMoreDetails" class="full-width-button">
        Show More Details
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      default: () => ({
        title: '',
        sent: 0,
        failed: 0,
        pending: 0,
        id: null,
      }),
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: this.payload.title,
      stats: {
        sent: this.payload.sent,
        failed: this.payload.failed,
        pending: this.payload.pending,
      },
    };
  },
  computed: {
    pageTitle() {
      return `Campaign Stats - ${this.title}`;
    },
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    async showMoreDetails() {
      let response = {};
      if (this.payload.campaign_type == 'one_off')
      {
        response = await this.$store.dispatch('contacts/get_records', {id: this.payload.id});
      }
      else if(this.payload.campaign_type == 'email')
      {
        response = await this.$store.dispatch('contacts/get_email_records', {id: this.payload.id});
      }
      this.payload = {
        records: response.data.records,
        title: this.title,
        type: this.payload.campaign_type
      }
      this.$emit('on-close');
      this.$emit('show-more', this.payload);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  display: flex;
  flex-direction: column;
  padding: 20px;

  margin: 0 auto;
  background-color: #fff;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}

.modal-body {
  margin: 20px 0;
  text-align: left;

  h2 {
    font-size: 1.6rem;
    margin-bottom: 15px;
    color: #333;
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .stat-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    color: #333;

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;

      &.sent {
        background-color: green;
      }
      &.failed {
        background-color: red;
      }
      &.pending {
        background-color: yellow;
      }
    }
  }
}

.modal-footer {
  margin-top: 20px;
}

.full-width-button {
  width: 100%;
  padding: 12px;
  margin: 0 20px;
  font-size: 1.1rem;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.full-width-button:hover {
  background-color: #0056b3;
}
</style>
